exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-new-js": () => import("./../../../src/pages/about-new.js" /* webpackChunkName: "component---src-pages-about-new-js" */),
  "component---src-pages-about-old-js": () => import("./../../../src/pages/about-old.js" /* webpackChunkName: "component---src-pages-about-old-js" */),
  "component---src-pages-boost-js": () => import("./../../../src/pages/boost.js" /* webpackChunkName: "component---src-pages-boost-js" */),
  "component---src-pages-boost-new-js": () => import("./../../../src/pages/boost-new.js" /* webpackChunkName: "component---src-pages-boost-new-js" */),
  "component---src-pages-boost-old-js": () => import("./../../../src/pages/boost-old.js" /* webpackChunkName: "component---src-pages-boost-old-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-new-js": () => import("./../../../src/pages/news-new.js" /* webpackChunkName: "component---src-pages-news-new-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-partner-test-js": () => import("./../../../src/pages/partner-test.js" /* webpackChunkName: "component---src-pages-partner-test-js" */),
  "component---src-pages-people-new-js": () => import("./../../../src/pages/people-new.js" /* webpackChunkName: "component---src-pages-people-new-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-showcase-old-js": () => import("./../../../src/pages/showcase-old.js" /* webpackChunkName: "component---src-pages-showcase-old-js" */),
  "component---src-pages-video-old-js": () => import("./../../../src/pages/video-old.js" /* webpackChunkName: "component---src-pages-video-old-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/partner.js" /* webpackChunkName: "component---src-templates-partner-js" */)
}

